<template>
    <div class="main">
        <div style="margin-top:10px;">
            <van-search
                v-model="objData.spmc"
                shape="round"
                placeholder="请输入商品名称"
                @search="onSearch"
            >
            </van-search>
        </div>
        <div  v-if="!errstate">    
            <van-pull-refresh v-model="state.refreshing" @refresh="onRefresh">
                <van-list
                    v-model:loading="state.loading"
                    :finished="state.finished"
                    finished-text="没有更多了"
                    @load="onLoad"
                >
                <van-cell center >

                    <div class="qjListItem">
                        <div class="divTitle divbold">商品名称</div>
                        <div class="divTitle divbold">规格型号</div>
                        <div class="divTitle divbold">税率</div>
                    </div>

                </van-cell>
                <van-cell center v-for="(item,index) in objData.cxArr" :key="index" @click="cellClick(item)">
                    <div class="qjListItem">
                        <div class="divTitle">{{item.spmc}}</div>
                        <div class="divTitle">{{item.gg}}</div>
                        <div class="divTitle">{{item.rate*100}}%</div>
                        
                    </div>
                </van-cell>
             </van-list>
            </van-pull-refresh>
        </div>
        <van-empty image="error" v-if="errstate" :description=description />
    </div>
</template>

<script>
import { ref,reactive,getCurrentInstance } from 'vue';
import Request from '../../api/commodity'
import config from "@/config/"
export default {
    name: "commodity",
    
    setup(){
        const { proxy } = getCurrentInstance();
        const page = ref("1");
        const pageSize = ref(config.request.pageSize);
        const errstate = ref(false);
        const description = ref("");
        const objData = reactive({
            spmc: "",
            cxArr:[]
        });
        const state = reactive({
            list: [],
            loading: false,
            finished: false,
            refreshing: false,
        });
        const searchMethod = (params)=> {
            Request.spgl_cx(params)
            .then(res=>{
                console.log('res=>',res);    
                // objData.cxArr = res.rows.datas;
                if (res.returnCode === "00") {  
                    errstate.value = false;
                        objData.cxArr = [];
                    if (state.refreshing) {
                        state.refreshing = false;
                        page.value = "1";
                    }
                    objData.cxArr.push(...res.rows.datas);  
                    state.loading = false;
                    page.value = Number(page.value) + 1;
                    if (objData.cxArr.length >= res.rows.total) {
                        state.finished = true;
                        console.log("11111111111");
                    }
                }else{
                    errstate.value = true;
                    description.value = res.returnMessage;
                }
            }).catch(err=>{
                console.log('err=>',err);   
            })
        };
        const onSearch = ()=> {
            page.value = "1";
            objData.cxArr = [];
            searchMethod({
                spmc:objData.spmc,
                page:'1',
                pageSize: pageSize.value
            });
            
        };
        const onLoad = () => {
            searchMethod({
                spmc:objData.spmc,
                page: page.value,
                pageSize: pageSize.value
            });
        };
        const onRefresh = () => {
                // 清空列表数据
                state.finished = false;

                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                state.loading = true;
                searchMethod({
                    spmc:objData.spmc,
                    page: "1",
                    pageSize: pageSize.value
                });
            };

        
         const cellClick = (item)=>{
            console.log(item);
            item.rate = item.rate*100;
            item.rate=item.rate+'%';
            proxy.$root.$router.push({name: "invoiceAdd", params:item});
            proxy.$root.$router.go(-1);
        }

        return{
            onSearch,

            objData,
            state,
            onRefresh,
            onLoad,
            errstate,
            description,
            page,
            pageSize,
            cellClick
        };
        
    }
}
</script>

<style scoped>
    .main {
        min-height: 100vh;
        position: relative;
    }
    .qjListItem{
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .divTitle{
        flex: 1;
        text-align: center;
    }
    .divbold{
        font-weight: bold;
    }
    .czspn{
        color:#1989fa;
    }
</style>